import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Company from "../components/danismanlik-company"
import rightArrow from "../assets/img/right-arrow.png";

import marka from "../assets/img/yenigifler.gif"
import surec1 from "../assets/img/landingbuilder-1.png"
import surec2 from "../assets/img/landingbuilder-2.png"
import surec3 from "../assets/img/landingbuilder-3.png"
import surec4 from "../assets/img/landingbuilder-4.png"
import surec5 from "../assets/img/landingbuilder-5.png"
import surec6 from "../assets/img/right.png"
import surec7 from "../assets/img/term1.png"
import surec8 from "../assets/img/term2.png"
import surec9 from "../assets/img/term3.png"
import term1 from "../assets/img/term4.png"
import term2 from "../assets/img/term5.png"
import term3 from "../assets/img/term6.png"
import smgif from "../assets/img/smgif.gif"
import filtregif from "../assets/img/filtregif.gif"
import nkl from "../assets/img/nklnew.png"
import landingbuilder from "../assets/img/landingbuilder-icerik.png"
import checkModal from "../assets/img/check-modal.png";



class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="MyLandingBuilder " description="MyLandingBuilder’ın yenilikçi araçlarını kullanarak dönüşüm için optimize edilmiş açılış sayfalarını dakikalar içerisinde kolaylıkla tasarlayın." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1 className="landingbuilerder-head">MyLandingBuilder</h1><br></br>
                            <span className="red-head">Herhangi Bir Kod Bilgisi Olmadan Çarpıcı Landing Page’ler Oluşturun!</span>
                            <p style={{ maxWidth: 550 }} className="arfiltre" >
Çevrimiçi varlığınızı bir üst seviyeye taşımaya hazır mısınız? 
Karşınızda MyLandingBuilder - herhangi bir kodlama uzmanlığına ihtiyaç duymadan büyüleyici landing page’ler zahmetsizce tasarlamak için mükemmel bir araç. 
İster deneyimli bir pazarlamacı, ister küçük bir işletme sahibi ya da hevesli bir girişimci olun, MyLandingBuilder, ürünlerinizi, hizmetlerinizi veya fikirlerinizi görsel olarak çarpıcı ve profesyonel bir şekilde sergilemenizi sağlar. 
Kodlamanın karmaşıklığına veda edin ve MyLandingBuilder ile yaratıcılığa ve sadeliğe merhaba deyin!
</p>
                            <input type="text" className="pink-input" placeholder="E-posta adresinizi girin..." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="social-button" onClick={(e) => this.showModal()}>Size özel teklif alın <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 33.5, paddingLeft: 50 }}><img alt="alt text" src={landingbuilder} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form>
                                    <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus id="site" required />
                                    <input type="text" placeholder="Adınızı girin." />
                                    <input type="email" placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container brands_content">
                    <h2 style={{ color: '#ea4f6a' }}>
                    MyLandingBuilder nedir?
                    </h2>
                    <p className="arfiltre">MyLandingBuilder, teknik geçmişi ne olursa olsun herkesin dönüşüm sağlayan etkileyici landing page’ler oluşturmasını sağlamak için Flatart tarafından tasarlanmış devrim niteliğinde bir platformdur.  Kullanıcı dostu bir arayüz ve parmaklarınızın ucundaki bir dizi güçlü özellik ile MyLandingBuilder, yüksek kaliteli landing page’ler oluşturmanın önündeki engelleri ortadan kaldırır. 
Sunduğumuz bu hizmet, tüm süreci kolaylaştırarak hem yeni başlayanlar hem de uzmanlar için erişilebilir ve keyifli hale getirir.</p>

                </div>

                <div className="onboarding container py60 news">
                <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 267 }} alt="alt text" src={surec1}></img>
                    </div>

                    <div className="col-md-6 landing">
                    <h3 style={{ color: '#ea4f6a' }}>
                    Sürükle ve Bırak
                    </h3>
                        <p className="arfiltre">MyLandingBuilder'ın sürükle ve bırak arayüzü, landing page’deki öğeleri zahmetsizce düzenlemenizi sağlar. Kodlama bilgisi gerekmeden - sadece resimler, metinler, düğmeler, formlar ve daha fazlası gibi öğeleri seçin ve dilediğiniz yere bırakın.</p>
                    </div>
                    

                </div>
                <div className="onboarding container news builder">
                    <div className="col-md-6 landing news">
                    <h3 style={{ color: '#ea4f6a' }} className="h3head">
                    Özelleştirilebilir Şablonlar
                    </h3>
                        <p className="arfiltre builder">Çeşitli sektörlere ve amaçlara uyacak şekilde profesyonelce tasarlanmış şablonlardan oluşan bir koleksiyondan seçim yapın. Vizyonunuza mükemmel şekilde uyması için bu şablonları markanız, içeriğiniz ve renk düzeninizle kişiselleştirin.
                        </p>
                    </div>
                    <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 318 }} alt="alt text" src={surec2}></img>
                    </div>

                </div>
                <div className="onboarding container py60 late">
                <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 255 }} alt="alt text" src={surec3}></img>
                    </div>

                    <div className="col-md-6 landing">
                    <h3 style={{ color: '#ea4f6a' }}>
                    Mobil Uyumlu Tasarım
                    </h3>
                        <p className="arfiltre">Mobil uyumlu tasarımımızla landing page’lerinizin her cihazda çarpıcı görünmesini sağlayın. MyLandingBuilder, akıllı telefonlar, tabletler ve masaüstü bilgisayarlardaki ziyaretçilere kusursuz bir deneyim sunmak için sayfanızın düzenini otomatik olarak uyarlar.</p>
                    </div>
                    

                </div>
                <div className="onboarding container news builder">
                    <div className="col-md-6 landing news">
                    <h3 style={{ color: '#ea4f6a' }} className="h3head">
                    Geniş Medya Kitaplığı
                    </h3>
                        <p className="arfiltre builder">Yüksek çözünürlüklü görseller, simgeler ve grafiklerle dolu kapsamlı bir medya kitaplığına erişin. Hedef kitlenizin dikkatini çeken görselleri kullanarak çekici öğelerle landing page’inizi zahmetsizce geliştirin.
                        </p>
                    </div>
                    <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 369 }} alt="alt text" src={surec4}></img>
                    </div>

                </div>

                <div className="onboarding container py60 late">
                <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 255 }} alt="alt text" src={surec5}></img>
                    </div>

                    <div className="col-md-6 landing">
                    <h3 style={{ color: '#ea4f6a' }}>
                    Dönüşüm Odaklı Öğeler
                    </h3>
                        <p className="arfiltre">Stratejik olarak yerleştirilmiş harekete geçirici butonlar, müşteri yakalama formları, geri sayım sayaçları ve daha fazlasıyla müşterilerinizi harekete geçirin. MyLandingBuilder, dönüşümleri en üst düzeye çıkarmak ve hedeflerinize ulaşmak için ihtiyaç duyduğunuz araçlarla sizi donatır.</p>
                    </div>
                </div>
                <div className="container manage-google builder" style={{ padding: '40px 60px' }}>
                    <h2 className="text">MyLandingBuilder Nasıl Kullanılır?</h2>
                    <p className="manage-google_p">MyLandingBuilder ile çarpıcı bir landing page oluşturmak çok kolay! İzlemeniz gereken adımlara göz atalım!</p>
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={surec7} />
                            <div>
                                <h3 className="text">Kaydolun</h3>
                                <p className="builder-news">Öncelikle, MyLandingBuilder.com üzerinden kaydolun. Unutmayın, MyLandingBuilder tamamen ücretsizdir!</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={surec8} />
                            <div>
                                <h3 className="text">Özelleştirin</h3>
                                <p className="builder-news">Şablonu değiştirmek için sürükle ve bırak özelliğini kullanın. Renkleri değiştirin, görseller yükleyin ve marka kimliğinize uyması için tüm ince ayarları yapın.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={surec9} />
                            <div>
                                <h3 className="text">Bir Şablon Seçin</h3>
                                <p className="builder-news">Özelleştirilebilir şablonlardan oluşan kütüphanemize göz atın. Projenizin hedeflerine ve tarzına en uygun olanı seçin.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={term1} />
                            <div>
                                <h3 className="text">Öğeler Ekleyin</h3>
                                <p className="builder-news">Formlar, düğmeler, resimler, videolar ve daha fazlası gibi öğeler ekleyerek landing page’inizin işlevselliğini artırın. Bunları sayfaya sürükleyin ve istediğiniz gibi konumlandırın.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item builderss">
                            <img alt="alt text" src={term2} />
                            <div>
                                <h3 className="text">Önizleme ve Yayınlama</h3>
                                <p className="builder-news">Landing page’inizi yayına almadan önce, her şeyin mükemmel göründüğünden emin olmak için önizleme yapın. Daha sonra, landing page’inizi dünyayla paylaşmak için yayınla düğmesine basın.</p>
                            </div>
                        </div>
                       
                    </div>
                </div>


                <div className="checkup checkup-index py60 container" >
                <div className="col-md-6">
                    <h2 className="check-head news">MyLandingBuilder ile Dönüşümlerinizi Artırın!</h2>
                    <p>Artık muhteşem landing page’ler yapmak için saatlerce uğraşarak kodlama öğrenmenize gerek yok! MyLandingBuilder, fikirlerinizi hayata geçirmenizi, hedef kitlenizin ilgisini çekmenizi ve anlamlı sonuçlar elde etmenizi sağlar - üstelik tüm bunları önceden herhangi bir kodlama bilgisi olmadan gerçekleştirir. 
Göz alıcı landing page’ler oluşturmaya bugün başlayın ve MyLandingBuilder ile çevrimiçi varlığınızı yeni zirvelere taşıyın!</p>

<Link  className="landingbuilders" to="https://mylandingbuilder.com/">Başarı hikayesine git</Link>
                </div>
                <div className="col-md-6">
                    <img alt="alt text" src={term3} />
                </div>
                
            </div>





            </Layout>
        )
    }
}
export default SocialMedia
